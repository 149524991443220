<template>
  <div class="incompleteTemplateOverview row">
    <LoadingPlaceholder v-if="loading" />

    <template v-else>
      <template v-if="originalTemplates && languages">
        <div class="input-group mb-3 col-12 col-md-6">
          <div class="input-group-prepend">
            <label class="input-group-text">{{ $t('filter') }}</label>
          </div>
          <select
            class="form-control"
            @input="changeLanguage($event)"
          >
            <option
              value="all"
              active="true"
              selected="true"
            >
              {{ $t('all') }}
            </option>
            <option
              v-for="(language, index) in languages"
              :key="`incompleteTemplateOverview-option-${ index }`"
              :value="language.language"
            >
              {{ language.language }} ({{ countMissing(language.language) }} {{ $t('incomplete').toLowerCase() }})
            </option>
          </select>
        </div>

        <div class="col-12">
          <table class="defaultTable">
            <thead>
              <tr>
                <th>{{ $t('templateName') }}</th>
                <th>{{ $t('translationProgress') }}</th>
                <th width="150">
                  {{ $t('options') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(template, index) in templates"
                :key="`incompleteTemplateOverview-tableRow-${ index }`"
              >
                <td>{{ template.name }}</td>
                <td class="align-middle h-100 py-0 pt-0 pm-0">
                  <span class="progress w-100">
                    <div
                      v-if="(template.messageCount / languages.length) * 100 !== 0"
                      :style="`width: ${ (template.messageCount / languages.length) * 100 }%;`"
                      :aria-valuenow="(template.messageCount / languages.length) * 100"
                      class="progress-bar bg-success"
                      role="progressbar"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >{{ template.messageCount }} / {{ languages.length }}</div>
                    <span v-else>{{ template.messageCount }} / {{ languages.length }}</span>
                  </span>
                </td>
                <td width="150">
                  <router-link
                    :to="{ path: `/smsmanagement/template/${ template.id }` }"
                    class="btn btn-sm btn-primary btn-block"
                  >
                    <font-awesome-icon
                      class="mr-2"
                      icon="edit"
                    />
                    <span>{{ $t('edit') }}</span>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>

      <p v-else>
        {{ $t('noDataAvailable') }}
      </p>
    </template>
  </div>
</template>

<script>
export default {
  name: "IcompleteTemplateOverview",
  metaInfo () {
    return {
      title: this.$t('incompleteTemplates')
    }
  },
  data () {
    return {
      originalTemplates: null,
      templates: null,
      languages: null,
      loading: true
    }
  },
  created () {
    this.getData();
  },
  methods: {
    changeLanguage (event) {
      this.templates = this.originalTemplates.filter((template) => {
        return template.missingLanguages.includes(event.target.value);
      });
      if (this.templates.length === 0) {
        this.templates = this.originalTemplates.slice();
      }
    },
    countMissing (language) {
      let amount = 0;
      this.originalTemplates.map((template) => {
        if (template.missingLanguages == null) {
          return 0;
        }
        template.missingLanguages.map((missingLanguage) => {
          if (language === missingLanguage) {
            amount++;
          }
        })
      });
      return amount;
    },
    getData () {
      this.axios.all([
        this.axios.get('/SmsManagement/GetIncompleteTemplates'),
        this.axios.get('/SmsManagement/GetDefaultLanguages')
      ])
        .then(this.axios.spread((incompleteTemplatesResponse, defaultLanguagesResponse) => {
          this.originalTemplates = incompleteTemplatesResponse.data;
          this.templates = incompleteTemplatesResponse.data;
          this.languages = defaultLanguagesResponse.data;
        }))
        .catch(() => {
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style scoped>
.progress {
  height: 30px;
  font-size: 1rem;
  line-height: 30px;
  position: relative;
}
.progress span {
  width: 100%;
  text-align: center;
  color: white;
}
</style>
